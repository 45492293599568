<template>
  <div>
    <z-header white />
    <div class="page_center">
      <div class="bread">
        <router-link to="/">首页></router-link>
        <span>商学院</span>
      </div>
    </div>
    <div class="page_center">
      <div class="left_content">
        <div v-if="recommendList.length > 0" class="popular_news_box">
          <router-link
            class="popular_news_list"
            :to="'/newsDetails/' + recommendList[0].objId"
          >
            <div class="image">
              <img
                :src="recommendList[0].headImg"
                :alt="recommendList[0].title"
              />
            </div>
            <p>{{ recommendList[0].title }}</p>
          </router-link>
          <div v-if="recommendList.length > 1" class="right_popular">
            <router-link
              class="popular_news_list"
              :to="'/newsDetails/' + recommendList[1].objId"
            >
              <div class="image">
                <img
                  :src="recommendList[1].headImg"
                  :alt="recommendList[1].title"
                />
              </div>
              <p>{{ recommendList[1].title }}</p>
            </router-link>
            <router-link
              v-if="recommendList.length > 2"
              class="popular_news_list"
              :to="'/newsDetails/' + recommendList[2].objId"
            >
              <div class="image">
                <img
                  :src="recommendList[2].headImg"
                  :alt="recommendList[2].title"
                />
              </div>
              <p>{{ recommendList[2].title }}</p>
            </router-link>
          </div>
        </div>
        <div class="news_type_box">
          <div>
            <span
              v-for="(item, index) in classifyList"
              :key="index"
              :class="{ active: classifyIndex === index }"
              @click="onClassify(index)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div>
          <router-link
            v-for="(item, index) of newsList"
            :key="index"
            class="news_list"
            :to="'/newsDetails/' + item.objId"
          >
            <div class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="news_info">
              <div>
                <div class="title">{{ item.title }}</div>
                <div class="description">
                  {{ item.introduce }}
                  <span>阅读全文</span>
                </div>
              </div>
              <div class="label">
                <span
                  v-for="(childItem, childIndex) of item.label"
                  :key="childIndex"
                  >{{ childItem }}</span
                >
              </div>
            </div>
          </router-link>
        </div>
        <z-page :current-page="pageNo" :total="pages" @change="getNewsList" />
      </div>
      <div class="right_content">
        <div class="content_title">开发服务类型</div>
        <div class="service_type_box">
          <router-link
            v-for="(item, index) in serviceTypeList"
            :key="index"
            :to="'/newsList/' + item.objId + '?name=' + item.name"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="content_title">APP商学院推荐阅读</div>
        <div class="recommend_list_box">
          <router-link
            v-for="(item, index) of operationSkillList"
            :key="index"
            class="recommend_list"
            :to="'/newsDetails/' + item.objId"
          >
            <div class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <div class="description">{{ item.introduce }}</div>
            </div>
          </router-link>
        </div>
        <div class="content_title">热门标签</div>
        <div class="service_type_box">
          <router-link
            v-for="(item, index) in popularList"
            :key="index"
            :to="'/newsList/' + item.objId + '?name=' + item.name"
            >{{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
    <z-footer />
  </div>
</template>

<script>
import zPage from "@/components/common/page.vue";
export default {
  components: {
    zPage,
  },
  data() {
    return {
      recommendList: [],
      pageNo: 1,
      total: 1,
      pages: 1,
      classifyList: [
        {
          name: "全部",
          objId: 0,
        },
      ],
      classifyIndex: 0,
      newsList: [],
      serviceTypeList: [],
      popularList: [],
      operationSkillList: [],
      baseInfo: {},
    };
  },
  created() {
    this.getArticleList(3, 3008);
    this.getArticleClassify();
    this.getArticleList();
    this.getBaseTypeClassify();
    this.getArticleList(3, 3005);
    this.getBaseInfo();
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },
    onClassify(index) {
      this.classifyIndex = index;
      this.pageNo = 1;
      this.getNewsList();
    },
    getNewsList(size) {
      if (size) {
        this.pageNo = size;
      }
      // eslint-disable-next-line prefer-const
      this.getArticleList();
    },

    //获取资讯分类列表
    getArticleClassify() {
      const defaultList = [
        {
          name: "全部",
          objId: 0,
        },
      ];
      this.$http.get("api/article/v1/classify").then((res) => {
        this.classifyList = defaultList.concat(res.data);
      });
    },
    //获取资讯列表
    getArticleList(pageSize = 10, type) {
      var httpData = {
        pageNo: this.pageNo,
        pageSize: pageSize,
      };
      if (type) {
        httpData.type = type;
      }
      if (this.classifyIndex >= 0) {
        httpData.classifyId = this.classifyList[this.classifyIndex].objId;
      }
      this.$http.post("api/article/v1/list", httpData).then((res) => {
        let Data = res.data;
        if (type == 3008) {
          this.recommendList = Data.data;
          return;
        }
        if (type == 3005) {
          this.operationSkillList = Data.data;
          return;
        }
        this.newsList = Data.data;
        this.pages = Data.pages;
        this.total = Data.count;
      });
    },

    //获取标签分类
    getBaseTypeClassify() {
      this.$http.get("api/base_type/classify").then((res) => {
        let Data = res.data;
        this.serviceTypeList = Data.articleLabelPlatform;
        this.popularList = Data.articleLabelHot;
      });
    },

    getBaseInfo() {
      this.$http.get("api/base_type/base_info").then((res) => {
        this.baseInfo = res.data.skillPage;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bread {
  margin-top: 30px;
  a {
    font-size: 14px;
    color: #333333;
    &:hover {
      color: #527fff;
    }
  }
  span {
    font-size: 14px;
    color: #333333;
  }
}
.page_center {
  display: flex;
  justify-content: space-between;
  .left_content {
    width: 790px;
    .popular_news_box {
      display: flex;
      justify-content: space-between;
      .popular_news_list {
        width: 490px;
        height: 321px;
        position: relative;
        cursor: pointer;
        .image {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          font-size: 18px;
          color: #ffffff;
          padding: 10px 20px;
          @include toe();
        }
      }
      .right_popular {
        width: 290px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .popular_news_list {
          width: 100%;
          height: 156px;
          p {
            font-size: 16px;
          }
        }
      }
    }
    .news_type_box {
      margin-top: 10px;
      width: 100%;
      overflow-x: auto;
      > div {
        display: flex;
        span {
          font-size: 17px;
          color: #999999;
          margin-right: 5px;
          flex-shrink: 0;
          cursor: pointer;
          padding: 20px 10px;
          position: relative;
          transition: transform 0.4s;
          &:hover {
            color: #527fff;
            transform: translateY(-3px);
          }
          &.active {
            font-size: 17px;
            color: #527fff;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10px;
              right: 10px;
              height: 4px;
              background-color: #527fff;
            }
          }
        }
      }
    }
    .news_list {
      padding: 30px 0;
      display: flex;
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      &:first-child {
        border-top: 0;
      }
      .image {
        width: 290px;
        height: 177px;
        flex-shrink: 0;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .news_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: bold;
        }
        .description {
          margin-top: 20px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          span {
            color: #527fff;
          }
        }
        .label {
          display: flex;
          flex-wrap: wrap;
          span {
            min-width: 90px;
            height: 30px;
            border: solid 1px #e5e5e5;
            padding: 0 20px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .right_content {
    width: 290px;
    .content_title {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      padding-top: 16px;
    }
    .service_type_box {
      margin-top: 23px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 28px;
      span,
      a {
        width: 140px;
        height: 40px;
        background-color: #f7f7f7;
        border: solid 1px #e5e5e5;
        font-size: 14px;
        color: #333333;
        text-align: center;
        line-height: 40px;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          color: #527fff;
        }
      }
    }
    .recommend_list_box {
      margin-top: 23px;
      margin-bottom: 40px;
      .recommend_list {
        display: flex;
        margin-bottom: 24px;
        cursor: pointer;
        .image {
          width: 130px;
          height: 80px;
          flex-shrink: 0;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          width: 143px;
          .title {
            font-size: 16px;
            color: #333333;
            @include toe();
            &:hover {
              color: #527fff;
            }
          }
          .description {
            font-size: 14px;
            color: #999999;
            margin-top: 11px;
            @include bov(2);
          }
        }
      }
    }
  }
}
</style>